<template>
	<div>
		<v-sheet class="dense-inputs">
			<v-row no-gutters>
                <div class="d-flex align-center pt-3">
                    <v-text-field
                        :placeholder="$t('message.number')"
                        :value="searchValues.title"
                        autocomplete="new-password"
                        class="filter-width-200 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValues.title = $event"
                        @click:append="searchIpas('title')"
                        @click:clear="resetSearch()"
                        @keyup.enter.prevent="searchIpas('title')"
                    ></v-text-field>
                    <v-autocomplete
                        :items="filterItems.buyer"
                        :loading="loading.filterItems.buyer"
                        :placeholder="$t('message.buyer')"
                        class="filter-width-200 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Customer.title"
                        item-value="Customer.id"
                        solo
                        v-model="filterValues.buyer"
                        @change="filterIpas()"
                    ></v-autocomplete>
                    <v-autocomplete
                        :items="filterItems.supplier"
                        :loading="loading.filterItems.supplier"
                        :placeholder="$t('message.spl')"
                        class="filter-width-200 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Supplier.title"
                        item-value="Supplier.id"
                        solo
                        v-model="filterValues.supplier"
                        @change="filterIpas()"
                    ></v-autocomplete>
                    <v-btn
                        :loading="loading.clear"
                        class="ml-2 px-2"
                        @click="clearFilters"
                    >{{ $t('message.clear') }}</v-btn>
                </div>
                <v-spacer></v-spacer>
                <ExportTableJsonWithAdd
                    :export-conditions="exportConditions"
                    :export-data="Ipas"
                    :export-fields="headers"
                    :export-source="'ipas'"
                    :has-add-function="true"
                    class="mt-3"
                    @add-action="newImportPermitAdvice"
                ></ExportTableJsonWithAdd>
                <HelpPopUpV2 help-page-component="IpasListing"></HelpPopUpV2>
			</v-row>
		</v-sheet>
		<v-overlay
			:value="loading.ipas"
			absolute
			opacity="0.15"
		>
			<v-row>
				<v-col class="text-center">
					<v-progress-circular
						color="primary"
						indeterminate
						size="40"
						width="6"
					></v-progress-circular>
				</v-col>
			</v-row>
		</v-overlay>
		<v-data-table
			:footer-props="{
				itemsPerPageOptions: [10,25,50,-1],
				showFirstLastPage: true
			}"
			:headers="headers"
            :hide-default-header="hideDefaultHeader"
            :hide-default-footer="hideDefaultFooter"
			:items="Ipas"
			:options="tableOptions"
			:server-items-length="totalIpas"
			calculate-widths
			class="mt-3 appic-table-light specification-table"
			dense
			id="importPermitAdviceTable"
			item-key="Ipa.id"
            @update:options="updateDataTable"
		>
<!--			<template v-slot:header="{ props: { headers } }">-->
<!--				<tr>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[0].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[1].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[2].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[3].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[4].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[5].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[6].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[7].text }}</th>-->
<!--					<th class="grey lighten-3 pa-1 width-1-pct">{{ headers[8].text }}</th>-->
<!--				</tr>-->
<!--			</template>-->
			<template v-slot:item.Ipa.Contract.title="{ item }">
				<span class="text-no-wrap font-weight-bold">{{ item.Ipa.Contract.version != null ? [item.Ipa.Contract.title, item.Ipa.Contract.revision_no , item.Ipa.Contract.partial_no].filter(Boolean).join('-') : item.Ipa.Contract.title }}</span>
			</template>
            <template v-slot:item.Ipa.date="{ item }">
                {{ item.Ipa.date != '0000-00-00' ? formatDate(item.Ipa.date) : '' }}
            </template>
            <template v-slot:item.Ipa.ipasent_date="{ item }">
                {{ item.Ipa.ipasent_date != '0000-00-00' ? formatDate(item.Ipa.ipasent_date) : '' }}
            </template>
			<template v-slot:item.Ipa.Customer.title="{ item }">
				<div class="text-no-wrap">{{ item.Ipa.Customer.otsname != '' ? item.Ipa.Customer.otsname : item.Ipa.Customer.title }}</div>
			</template>
			<template v-slot:item.Ipa.Contract.etddate="{ item }">
				{{ item.Ipa.Contract.etddate != '0000-00-00' ? formatDate(item.Ipa.Contract.etddate) : '' }}
			</template>
			<template v-slot:item.Ipa.Contract.etadate="{ item }">
				{{ item.Ipa.Contract.etadate != '0000-00-00' ? formatDate(item.Ipa.Contract.etadate) : '' }}
			</template>
			<template v-slot:item.Ipa.Contract.shipmentstatus_id="{ item }">
                <span class="text-no-wrap" v-if="item.Ipa.Contract.shipmentstatus_id == 6">
                    <v-icon x-small color="grey" class="mr-1">fas fa-ship</v-icon><span :class="item.Ipa.Contract.shipmentstatus_text && item.Ipa.Contract.shipmentstatus_text.length > 10 ? 'font-xs' : ''">{{ getShipmentStatus(item.Ipa.Contract.shipmentstatus_id, item.Ipa.Contract.shipmentstatus_text) }}</span>
                </span>
			</template>
			<template v-slot:item.Ipa.id="{ item }">
				<v-menu>
					<template v-slot:activator="{ on: menu }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
									<v-icon>more_vert</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('message.moreActions') }}</span>
						</v-tooltip>
					</template>
					<v-list dense>
						<v-list-item class="font-sm" @click="updateIpa(item.Ipa.id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateIpa') }}</v-list-item>
						<v-list-item class="font-sm" @click="cancelIpa(item.Ipa.id, (item.Ipa.Contract.version != null ? [item.Ipa.Contract.title, item.Ipa.Contract.revision_no , item.Ipa.Contract.partial_no].filter(Boolean).join('-') : item.Ipa.Contract.title))"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelIpa') }}</v-list-item>
<!--						<v-list-item class="font-sm" @click="viewPdf(item.Ipa.id)"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>-->
					    <PrintImportPermitAdviceButton
                            :import-permit-advice-id="item.Ipa.id"
                            :list-mode="true"
                            :update-mode="true"
                        />
                    </v-list>
				</v-menu>
			</template>
		</v-data-table>
		<SimpleAlert
			:alert_message.sync="dialogs.error_message"
			:dialog.sync="dialogs.error"
			@dialog-closed="dialogClosed"
		></SimpleAlert>
	</div>
</template>

<script>
	import {formatDate} from "Helpers/helpers";
	// import SimpleAlert from "./SimpleAlert";
	import {numberFormat} from "../../helpers/helpers";
	import {mapGetters, mapActions} from "vuex";
	import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import { v4 as uuidv4 } from 'uuid';
    import {api} from "Api";

    const ExportTableJsonWithAdd = () => import("./ExportTableJsonWithAdd");
    const SimpleAlert = () => import("./SimpleAlert");
    const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");
    const PrintImportPermitAdviceButton = () => import("./Printing/PrinImportPermitAdviceButton.vue");

	export default {
		name: "IpasListingNew",
		components: {PrintImportPermitAdviceButton, HelpPopUpV2, ExportTableJsonWithAdd, SimpleAlert},
		data() {
			return {
                currentFilter: null,
                currentSearch: null,
				dialogs: {
					error:  false,
					error_message: ""
				},
                exportConditions: {},
                filterItems: {
                    buyer: [],
                    supplier: []
                },
				filterOptions: {
					status: {
						Status: {
							id: 1,
							title: 'open'
						}
					}
				},
                filterValues: {
                    buyer: null,
                    supplier: null
                },
                hideDefaultHeader: false,
                hideDefaultFooter: false,
				loading: {
					filter: {
						buyers: false
					},
                    filterItems: {
                        buyer: null,
                        supplier: null
                    },
					search: false,
					ipas: false
				},
				searchField: 'Ipa.Contract.title', //default search field
				searchTerm: null,
                searchValues: {
                    title: null
                },
				tableOptions: {},
			}
		},
		computed: {
			...mapMultiRowFields('ipa',{
				Ipas: 'current.Ipas'
			}),
			...mapFields('ipa',{
				totalIpas: 'current.totalIpas'
			}),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
			...mapGetters([
				'shipmentStatuses'
			]),
			formatDate: () => formatDate,
			headers () {
				let headers = [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'Ipa.id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        sortable: false
                    },
                    {
                        id: 1,
                        text: this.$t('message.date'),
                        value: 'Ipa.date',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 2,
                        text: this.$t('message.number'),
                        value: 'Ipa.Contract.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 3,
                        text: this.$t('message.ipaSent'),
                        value: 'Ipa.ipasent_date',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 4,
                        text: this.$t('message.buyer'),
                        value: 'Ipa.Customer.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
					{
						id: 5,
						text: this.$t('message.supplier'),
						value: 'Ipa.Supplier.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
					},
					{
						id: 6,
						text: this.$t('message.agent'),
						value: 'Ipa.Agent.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
					},
                    {
                        id: 7,
                        text: this.$t('message.shipmentStatus'),
                        value: 'Ipa.Contract.shipmentstatus_id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
					{
						id: 8,
						text: this.$t('message.etd'),
						value: 'Ipa.Contract.etddate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
					},
					{
						id: 9,
						text: this.$t('message.eta'),
						value: 'Ipa.Contract.etadate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
					},
					{
						id: 10,
						text: this.$t('message.pod'),
						value: 'Ipa.Entryport.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
					},
                    {
                        id: 11,
                        text: this.$t('message.destination'),
                        value: 'Ipa.Destination.country',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    }
				]
				return headers
			},
			searchFields() {
				return this.headers.filter((header)=>header.searchable === true)
			},
		},
		methods: {
			...mapActions('ipa',{
				cancelIpaById: 'cancelIpaById',
				getAllIpas: 'getAllIpas',
				searchAllIpas: 'searchAllIpas'
			}),
			async cancelIpa (val, title) {
				if(await this.$root.$confirm(this.$t('message.cancelIpa') + ' ' + title, this.$t('message.confirmations.continueIpaCancelAction'), {color: 'orange'})){
					this.cancelIpaById(val).then((status) => {
						if(status == 'done'){
							this.$toast.success(this.$t('message.successes.ipaDeleted'),
								{
                                    classes: ['icon-float-left'],
									icon: 'check_circle_outline'
								}
							)
							this.loadIpas()
						} else {
							this.$toast.error(this.$t('message.errors.ipaNotDeleted'),
								{
                                    classes: ['icon-float-left'],
									icon: 'error_outline'
								}
							)
						}
					})
					.catch(()=>{
						this.$toast.error(this.$t('message.errors.ipaNotDeleted'),
							{
                                classes: ['icon-float-left'],
								icon: 'error_outline'
							}
						)
					})
					.finally(()=>{

					})
				}
			},
            clearFilters() {
                this.loading.clear = true
                this.filterValues.buyer = null
                this.filterValues.supplier = null
                this.searching = false
                this.searchValues.title = null
                this.tableOptions.page = 1
                this.tableOptions.sortBy[0] = 'Ipa.lcadate'
                this.tableOptions.sortDesc[0] = true
                this.loading.clear = false
                this.filterLcas()
            },
			dialogClosed () {
				this.dialogs.error = false;
				this.dialogs.error_message = ''
			},
            filterIpas() {
                this.searchValues.title = null
                let noValues = true
                const keys = Object.keys(this.filterValues)
                keys.forEach((key, index) => {
                    if(this.filterValues[key] != null) noValues = false
                })
                if(noValues == false) {
                    let conditions = []
                    if(this.filterValues.buyer != null) conditions.push({field: 'Ipa.Contract.customer_id', value: this.filterValues.buyer})
                    if(this.filterValues.supplier != null) conditions.push({field: 'Ipa.Contract.supplier_id', value: this.filterValues.supplier})

                    if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = 10

                    if(_.isEqual(this.currentFilter,this.filterValues) === false) {
                        this.tableOptions.page = 1
                        this.currentFilter = _.cloneDeep(this.filterValues)
                    }

                    if(_.has(this.tableOptions, 'sortBy') == false) {
                        this.tableOptions['sortBy'] = ['Ipa.created']
                    }
                    if(this.tableOptions.sortBy.length == 0){
                        this.tableOptions['sortBy'] = ['Ipa.created']
                    }
                    if(_.has(this.tableOptions, 'sortDesc') == false){
                        this.tableOptions['sortDesc'] = [false]
                        if(this.tableOptions.sortBy[0] == 'Ipa.created') {
                            this.tableOptions['sortDesc'] = [true]
                        }
                    }
                    if(this.tableOptions.sortBy[0] == 'Ipa.created' && this.tableOptions.sortDesc.length == 0){
                        this.tableOptions['sortDesc'] = [true]
                    }

                    let order = [{field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'}]

                    let payload = {
                        tableOptions: this.tableOptions,
                        conditions: conditions,
                        order: order
                    }

                    this.hideDefaultFooter = false
                    this.loading.ipas = true;
                    this.searchAllIpas(payload)
                        .then(() => {
                            this.loading.ipas = false;
                        })
                        .catch(()=>{
                            this.loading.ipas = false;
                        })
                        .finally(()=>{
                            this.loading.ipas = false;
                        })
                } else {
                    this.loadIpas()
                }
            },
			formatThisNumber(value,format){
				return numberFormat(value,format)
			},
			getShipmentStatus (id, text) {
				if(id) {
					let status = this.shipmentStatuses.find(d => d.Shipmentstatus.id == id)
					if(status?.Shipmentstatus?.hastext == 1){
						if(id == 1){
							if(parseInt(text) == text){
								return status?.Shipmentstatus?.title + ': ' + text
							} else {
								return text
							}
						} else {
							return text
						}
					} else {
						return status?.Shipmentstatus?.title
					}
				}
				return null
			},
            loadFilterItems(filter) {
                return new Promise((resolve, reject) => {
                    if(filter && filter.length > 0) {
                        this.loading.filterItems[filter] = true
                        api
                            .get('/ipas/filter-options/' + filter)
                            .then(response => {
                                if(response.data.status == 'success') {
                                    this.filterItems[filter] = response.data.data
                                    this.loading.filterItems[filter] = false
                                    resolve('done')
                                } else {
                                    this.loading.filterItems[filter] = false
                                    reject('error')
                                }
                            })
                            .catch(error => {
                                this.loading.filterItems[filter] = false
                                reject(error)
                            })
                    } else {
                        reject('Filter empty')
                    }
                })
            },
			loadIpas () {
				// this.loading.ipas = true;
				// let payload = {
				// 	tableOptions: this.tableOptions,
				// 	filterOptions: this.filterOptions
				// }
                this.hideDefaultFooter = false
                this.loading.ipas = true;

                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = 10
                if(this.tableOptions.page == null) this.tableOptions.page = 1

                let payload = {
                    tableOptions: this.tableOptions,
                    // filterOptions: this.filterOptions
                }
				this.getAllIpas(payload)
					.then(()=>{
						this.loading.ipas = false;
					})
					.catch(()=>{
						this.loading.ipas = false;
					})
					.finally(()=>{
						this.loading.ipas = false;
					})
			},
            newImportPermitAdvice() {
                let tab = window.open('/v1/ipas/add', '_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
			resetSearch () {
				// this.hideDefaultFooter = false
				// this.loading.ipas = false
				// this.searchField = 'Ipa.Contract.title'; //default search field
				// this.searchTerm = null;
				// this.tableOptions.itemsPerPage = 10
				// this.filterOptions = {};

                this.searching = false
                this.hideDefaultFooter = false
                this.filterValues.buyer = null
                this.filterValues.supplier = null
                this.tableOptions.page = 1
                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = 10

                this.tableOptions.sortBy = []
                this.tableOptions.sortDesc = []
                this.filterIpas()
			},
			searchIpas(field) {
                if(this.searchValues[field] == null || this.searchValues[field].length == 0){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.errors.noSearchTerm')
                } else {
					//TODO search ipas
					// this.filterOptions = {}
					// this.hideDefaultFooter = true
					// this.loading.ipas = true;
					// this.loading.search = true;
					// let payload = {
					// 	tableOptions: this.tableOptions,
					// 	search: {
					// 		field: this.searchField,
					// 		value: this.searchTerm
					// 	}
					// }
                    this.searching = true
                    this.filterValues.buyer = null
                    this.filterValues.supplier = null

                    const keys = Object.keys(this.searchValues)
                    keys.forEach((key, index) => {
                        if(key != field) {
                            this.searchValues[key] = null
                        }
                    })

                    let fieldName = null
                    let sortFieldName = null
                    switch(field){
                        case 'title':
                            fieldName = 'Ipa.Contract.title'
                            sortFieldName = 'Ipa.date'
                            break
                    }

                    this.loading.ipas = true

                    let payload = {}
                    let order = []

                    if (_.isEqual(this.currentSearch, this.searchValues) === false) {
                        //new search = new sort
                        order.push({field: fieldName, direction: ['title'].includes(field) ? 'ASC' : 'DESC'})
                        this.tableOptions.sortBy[0] = sortFieldName
                        this.tableOptions.sortDesc[0] = ['title'].includes(field) ? false : true
                        this.currentSearch = _.cloneDeep(this.searchValues)
                        //new search new page
                        this.tableOptions.page = 1
                    } else {
                        //same search new sort
                        if(this.tableOptions.sortBy.length == 0){
                            this.tableOptions.sortBy[0] = sortFieldName
                        }
                        order.push({field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'})
                    }

                    if(fieldName != null) {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [
                                {
                                    field: fieldName,
                                    value: this.searchValues[field]
                                }
                            ],
                            order: order
                        }
                    } else {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [],
                            order: order
                        }
                    }

                    this.searchAllIpas(payload)
						.then(() => {
							this.loading.search = false;
							this.loading.ipas = false;
						})
						.catch(()=>{
							this.loading.search = false;
							this.loading.ipas = false;
						})
						.finally(()=>{
							this.loading.search = false;
							this.loading.ipas = false;
						})
				}
			},
            updateDataTable(options) {
                this.tableOptions = options
                if(this.searching === true)  {
                    // check which field is not empty
                    let field = null
                    if(this.searchValues.title != null) field = 'title'
                    this.searchIpas(field)
                } else {
                    this.filterIpas()
                }
            },
			updateIpa (val) {
                let tab = window.open('/v1/ipas/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
				// router.push({name: 'update_ipa', params: { ipaId : val}}).catch(err => {})
			},
			viewPdf (ipaId) {
                let ipa = this.Ipas.find( i => i.Ipa.id == ipaId)
                let document = encodeURIComponent((ipa.Ipa.Contract.version != null ? [ipa.Ipa.Contract.title, ipa.Ipa.Contract.revision_no , ipa.Ipa.Contract.partial_no].filter(Boolean).join('-') : ipa.Ipa.Contract.title) + '_IPA')
				let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
					process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
					+ process.env.VUE_APP_BASE_URL
					+ '/v1/ipas/print/'
					+ ipaId
					+ '/'
					+ uuidv4()
					+ '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
					, "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
			},
		},
		watch: {
			// filterOptions: {
			// 	handler(){
			// 		if(!this.loading.search) {
			// 			this.loadIpas()
			// 		}
			// 	},
			// 	deep: true
			// },
			// tableOptions: {
			// 	handler(){
			// 		this.loadIpas()
			// 	},
			// 	deep: true
			// },
		},
		created(){
			// let dt = new Date()
			// let defaultYear = dt.getFullYear() - 1
            this.searchValues.title = null
            this.filterValues.buyer = null
            this.filterValues.supplier = null

			this.loadIpas()

            if(this.filterItems.buyer.length == 0) this.loadFilterItems('buyer')
            if(this.filterItems.supplier.length == 0) this.loadFilterItems('supplier')
		}
	}
</script>

<style>
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>